import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { FlatfileButton } from "@flatfile/react";
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'


const StyledFlatfileButton = styled(FlatfileButton)`
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    color: rgb(101,26,49);
    border-color: rgb(101,26,49);
    background-color: transparent;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    :hover {
        background-color: rgb(101,26,49);
        color: white;
    }
`

class ImportForm extends Component {

  postEvents = (events) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    return axios.post(this.props.importUrl, events)
    .then((res) => {
      const successMsg = `${I18n.t('import.success', {x: res.data.length})}`
      return successMsg
    })
    .catch((err) => {
      let errorMsg = I18n.t('import.failure')
      err.response.data.map(event => {errorMsg += `${String.fromCodePoint(0x000A)}• Row ${event.error_info.row_number} - ${event.error_msg}`})
      return errorMsg
    })
  }

  render() {
    return (
      <div>
        <Row>
          <Col md={12}>
            <span dangerouslySetInnerHTML={{__html: I18n.t(this.props.guidance) }}></span>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col md={12}>
            <StyledFlatfileButton
              licenseKey={ this.props.licenseKey }
              customer={{ userId: this.props.userID }}
              settings={ this.props.schema }
              onData = { async (results) => {
                return await this.postEvents(results.data)
              }}
            >
            { I18n.t('import.import') }
            </StyledFlatfileButton>
          </Col>
        </Row>
          
      </div>
    );
  }
}

ImportForm.propTypes = {
  userID: PropTypes.number,
  schema: PropTypes.object,
  authenticityToken: PropTypes.string,
  licenseKey: PropTypes.string,
};

export default ImportForm
