import React, { useState, useEffect } from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { Form, Row, Col, Button, Modal, Alert, Tab, Tabs } from 'react-bootstrap'
import SelectSingle from '../selects/SelectSingle.js'
import LessonResourceTable from '../tables/LessonResourceTable.js'
import ResourceForm from '../forms/ResourceForm.js'
import Comments from '../Comments.js'
import { appConstants, localTimezone } from 'utils/constants.js'
import { formatValidationErrors } from 'utils/format.js'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment-timezone'

// Flags have been renamed to Lessons in SMLP2
export default function LessonForm(props) {
  const [id, setId] = useState(props.id || '')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [dateEntered, setDateEntered] = useState(moment())
  const [issue, setIssue] = useState('')
  const [proposedAction, setProposedAction] = useState('')
  const [numberOfChanges, setNumberOfChanges] = useState('')
  const [surveyType, setSurveyType] = useState('')
  const [status, setStatus] = useState('lm_issue_identified')
  const [theme, setTheme] = useState('')
  const [ipComments, setIpComments] = useState('')
  const [ipCommentsExist, setIpCommentsExist] = useState(false)
  const [ipCommentsUpdated, setIpCommentsUpdated] = useState(null)
  const [ipCommentsUpdater, setIpCommentsUpdater] = useState('')
  const [bhaComments, setBhaComments] = useState('')
  const [bhaCommentsUpdated, setBhaCommentsUpdated] = useState(null)
  const [bhaCommentsUpdater, setBhaCommentsUpdater] = useState('')
  const [semesterId, setSemesterId] = useState('')
  const [activityId, setActivityId] = useState('')
  const [issueTypeId, setIssueTypeId] = useState('')
  const [discussionClosed, setDiscussionClosed] = useState(false)

  const [tabKey, setTabKey] = useState(props.tabKey || 'details')
  const [alertMessage, setAlertMessage] = useState('')
  const [updated, setUpdated] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [createDuplicate, setCreateDuplicate] = useState(false)
  const [showAddResourceModal, setShowAddResourceModal] = useState(false)


  useEffect(() => {
    if (props.id) {
      const fetchData = async () => {
        const response = await axios.get(`${appConstants.FLAGS_URL}/${props.id}.json`)
        setName(response.data.name || "")
        setDescription(response.data.description || "")
        setDateEntered(response.data.date_entered ? moment(response.data.date_entered) : moment())
        setIssue(response.data.lesson || "")
        setProposedAction(response.data.proposed_action || "")
        setNumberOfChanges(response.data.number_of_changes || "")
        setStatus(response.data.learning_module_status || 'lm_issue_identified')
        setTheme(response.data.lesson_theme || "")
        setIpComments(response.data.ip_comments || "")
        setIpCommentsExist(response.data.ip_comments && response.data.ip_comments.length > 0)
        setIpCommentsUpdated(response.data.ip_comments_updated || null)
        setIpCommentsUpdater(response.data.ip_comments_updater || "")
        setBhaComments(response.data.bha_comments || "")
        setBhaCommentsUpdated(response.data.bha_comments_updated || null)
        setBhaCommentsUpdater(response.data.bha_comments_updater || "")
        setActivityId(response.data.activity_id || "")
        setSemesterId(response.data.semester_id || "")
        setIssueTypeId(response.data.issue_type_id || "")
        setDiscussionClosed(response.data.discussion_closed || false)
      }

      fetchData()
    }
  }, [props.id])

  useEffect(() => {
    if (updated) {
      props.refreshData()
      setUpdated(false)
    }
    if (deleted) {
      props.refreshData()
      setDeleted(false)
      props.closeModal()
    }
  }, [updated, deleted])

  useEffect(() => {
    if (createDuplicate && !id) {
      setCreateDuplicate(false)
    }
  }, [createDuplicate])

  useEffect(() => {
    if (ipComments !== "") {
      setIpCommentsExist(true)
    }
  }, [ipCommentsUpdated])

  const createFormData = () => {
    let formData = new FormData()
    formData.append('flag[name]', name)
    formData.append('flag[description]', description)
    formData.append('flag[date_entered]', dateEntered)
    formData.append('flag[lesson]', issue)
    formData.append('flag[activity_id]', activityId)
    formData.append('flag[lesson_theme]', theme)
    formData.append('flag[semester_id]', semesterId)
    formData.append('flag[learning_module_status]', status)
    formData.append('flag[proposed_action]', proposedAction)
    formData.append('flag[number_of_changes]', numberOfChanges)
    formData.append('flag[survey_type]', surveyType)
    formData.append('flag[issue_type_id]', issueTypeId)
    formData.append('flag[ip_comments]', ipComments)
    formData.append('flag[bha_comments]', bhaComments)
    formData.append('flag[discussion_closed]', discussionClosed)

    return formData
  }

  const handleSubmit = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const data = createFormData()
    let notice = id ? I18n.t("notices.update_success") : I18n.t("notices.create_success")
    if (id) {
      await axios.put(`${appConstants.FLAGS_URL}/${id}`, data )
      .then(res => {
        setId(res.data.id)
        setIpCommentsUpdated(res.data.ip_comments_updated)
        setIpCommentsUpdater(res.data.ip_comments_updater)
        setBhaCommentsUpdated(res.data.bha_comments_updated)
        setBhaCommentsUpdater(res.data.bha_comments_updater)
      })
      .catch(err => {
        console.log(err)
        notice = formatValidationErrors('flag', err.response.data)
      })
    } else {
      await axios.post(appConstants.FLAGS_URL, data )
      .then(res => {
        setId(res.data.id)
        setIpCommentsUpdated(res.data.ip_comments_updated)
        setIpCommentsUpdater(res.data.ip_comments_updater)
        setBhaCommentsUpdated(res.data.bha_comments_updated)
        setBhaCommentsUpdater(res.data.bha_comments_updater)
      })
      .catch(err => {
        console.log(err)
        notice = formatValidationErrors('flag', err.response.data)
      })
    }
    setAlertMessage(notice)
    setUpdated(true)
  }

  const handleDelete = async () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = props.authenticityToken;
    const res = await axios.delete(`${appConstants.FLAGS_URL}/${id}.json`)
    const notice = res.status === 204 ? I18n.t("notices.delete_success") : I18n.t("notices.delete_failure")
    setAlertMessage(notice)
    setDeleted(true)
  }

  const handleAlertMessage = () => {
    if (alertMessage){
      return (
        <Alert variant={'info'} onClose={() => setAlertMessage(null)} dismissible>
          {alertMessage}
        </Alert>
      )
    } else {
      return ""
    }
  };

  const renderDeleteButton = () => {
    if (id && props.allowed.create_lesson) {
      return (<Button variant="outline-danger" style={{float: 'right'}} onClick={handleDelete}>{I18n.t("navigation.delete")}</Button>)
    }
  }

  const renderAddUpdateButton = () => {
    if (props.allowed.edit_lesson) {
      return (<Button variant="outline-primary" onClick={handleSubmit}>{id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button>)
    }
  }

  const renderDuplicateButton = () => {
    if (id && props.allowed.create_lesson) {
      return (<Button variant="outline-primary" onClick={() => {setId(""); setName(""); setCreateDuplicate(true)}}>{I18n.t("navigation.duplicate")}</Button> )
    }
  }

  const renderCloseDiscussionSwitch = () => {
    return id && props.allowed.create_lesson ? (
      <div className='float-end label-wrap'>
        <Form.Label>{I18n.t('activerecord.attributes.flag.closed')}&nbsp;&nbsp;</Form.Label>
          <Form.Check
            type="switch"
            name="discussionClosed"
            checked={discussionClosed}
            value={false}
            onChange={() => setDiscussionClosed(!discussionClosed)}
            bsPrefix="discussionClosed-switch" />
      </div>
    ) : id && discussionClosed ? <span className="fst-italic fw-lighter">&nbsp;&nbsp;({I18n.t('activerecord.attributes.flag.closed')})</span> : ""
  }

  return (
    <Form>
      <Modal
        show={showAddResourceModal}
        onHide={()=>setShowAddResourceModal(false)}
        keyboard={true}
        size={'xl'}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('resource.add_a_resource')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ResourceForm
            authenticityToken={props.authenticityToken}
            isModal={true}
            closeModal={()=>setShowAddResourceModal(false)}
            resource={{activities: [{id: activityId}], lessons: [{id: id}]}}
          />
        </Modal.Body>
      </Modal>
      <Row>
        <Col>
          {handleAlertMessage()}
        </Col>
      </Row>
      <Tabs activeKey={tabKey} id="sitevisit-tabs" onSelect={(k) => setTabKey(k)}>
        <Tab eventKey="details" title={I18n.t('navigation.lesson_details_tab')} className="mb-3">
          <Row>
            <Form.Group as={Col} md={4} className="mb-3 mt-3" controlId="lessonForm.name">
              <Form.Label>{I18n.t('activerecord.attributes.flag.name')}</Form.Label>
              <Form.Control
                name="name"
                type="text"
                value={name}
                disabled={true}
                onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={2} className="mb-3 mt-3" controlId="lessonForm.date_entered">
              <Form.Label>{I18n.t('activerecord.attributes.flag.date_entered')}</Form.Label><br/>
              <SingleDatePicker
                disabled={true}
                date={dateEntered}// momentPropTypes.momentObj or null
                onDateChange={() => {}} // PropTypes.func.isRequired
                onFocusChange={() => {}} // PropTypes.func.isRequired
                id="date_entered" // PropTypes.string.isRequired
                isOutsideRange={()=>false} />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3 mt-3" controlId="lessonForm.issue">
              <Form.Label>{I18n.t('activerecord.attributes.flag.issue')}</Form.Label>
              <Form.Control
                name="issue"
                as="textarea"
                rows="4"
                value={issue}
                disabled={!props.allowed.create_lesson}
                onChange={(e) => setIssue(e.target.value)} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlId="lessonForm.activity">
              <Form.Label>{I18n.t('activerecord.attributes.flag.activity')}</Form.Label>
              <SelectSingle
                selected={activityId}
                onValueChange={(e) => setActivityId(e ? e.value : "")}
                url={appConstants.ACTIVITIES_URL + '.json'}
                isDisabled={!props.allowed.create_lesson}
                placeholder={'-'} />
            </Form.Group>
            <Form.Group as={Col} md={4} className="mb-3" controlId="lessonForm.theme">
              <Form.Label>{I18n.t('activerecord.attributes.flag.theme')}</Form.Label>
              <SelectSingle
                selected={theme}
                onValueChange={(e) => setTheme(e ? e.value : "")}
                url={appConstants.LESSON_THEMES_URL}
                isDisabled={!props.allowed.create_lesson}
                placeholder={'-'} />
            </Form.Group>
            <Form.Group as={Col} md={4} className="mb-3" controlId="lessonForm.semester">
              <Form.Label>{I18n.t('activerecord.attributes.flag.semester')}</Form.Label>
              <SelectSingle
                selected={semesterId}
                onValueChange={(e) => setSemesterId(e ? e.value : "")}
                url={appConstants.SEMESTERS_URL + '.json'}
                isDisabled={!props.allowed.create_lesson}
                placeholder={'-'} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={4} className="mb-3" controlId="lessonForm.status">
              <Form.Label>{I18n.t('activerecord.attributes.flag.learning_module_status')}</Form.Label>
              <SelectSingle
                selected={status}
                onValueChange={(e) => setStatus(e ? e.value : "")}
                isClearable={false}
                url={appConstants.LEARNING_MODULE_STATUSES_URL}
                isDisabled={!props.allowed.create_lesson}
                placeholder={'-'} />
            </Form.Group>
            <Form.Group as={Col} md={8} className="mb-3" controlId="lessonForm.proposed_action">
              <Form.Label>{I18n.t('activerecord.attributes.flag.proposed_action')}</Form.Label>
              <Form.Control
                name="proposed_action"
                as="textarea"
                rows="4"
                value={proposedAction}
                disabled={!props.allowed.create_lesson}
                onChange={(e) => setProposedAction(e.target.value)} />
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="lessonForm.buttons">
            { props.allowed.create_lesson ? renderAddUpdateButton() : "" } &nbsp;
            { renderDuplicateButton() }
            { renderDeleteButton() }
          </Form.Group>
        </Tab>
        <Tab eventKey="feedback" title={I18n.t('navigation.lesson_feedback_tab')} className="mb-3">
          <Row>
            <Form.Group as={Col} md={6} className="mb-3 mt-3" controlId="lessonForm.number_of_changes">
              <Form.Label>{I18n.t('activerecord.attributes.flag.number_of_changes')}</Form.Label>
              <Form.Control
                name="number_of_changes"
                type="text"
                value={numberOfChanges}
                disabled={!props.allowed.edit_lesson}
                onChange={(e) => setNumberOfChanges(e.target.value)} />
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3 mt-3" controlId="lessonForm.description_of_change">
              <Form.Label>{I18n.t('activerecord.attributes.flag.description')}</Form.Label>
              <Form.Control
                name="description"
                as="textarea"
                value={description}
                disabled={!props.allowed.edit_lesson}
                onChange={(e) => setDescription(e.target.value)}
                rows="4" />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md={6} className="mb-3" controlId="lessonForm.ip_comments">
              <Form.Label>{I18n.t('activerecord.attributes.flag.ip_comments')} &nbsp; {ipCommentsUpdated ? <span className="fst-italic fw-lighter">{I18n.t('activerecord.attributes.flag.comments_updated')}: {moment(ipCommentsUpdated).tz(localTimezone()).format('MMMM Do YYYY, h:mm:ss a')} by {ipCommentsUpdater}</span> : ""} </Form.Label>
              <Form.Control
                name="ip_comments"
                as="textarea"
                value={ipComments || ""}
                disabled={!props.allowed.ip_lesson_comments}
                onChange={(e) => setIpComments(e.target.value)}
                rows="4" />
              <Form.Group className="mb-3 mt-3" controlId="lessonForm.buttons">
                { renderAddUpdateButton() }
              </Form.Group>
            </Form.Group>
            { props.id && ipCommentsExist ?
              (
                <Form.Group as={Col} className="mb-3" controlId="lessonForm.comments">
                  <Form.Label>{I18n.t('navigation.discussion')} </Form.Label>
                  { renderCloseDiscussionSwitch() }
                  <Comments
                    objectId={props.id}
                    objectType="flag"
                    commentType="lesson"
                    open={!discussionClosed}
                    sort={"asc"}
                    authenticityToken={props.authenticityToken}
                    className="mb-3" />
                </Form.Group>
              ) : ""
            }
          </Row>
        </Tab>
        { props.id ?
          (
            <Tab eventKey="resources" title={I18n.t('navigation.lesson_resources_tab')} className="mb-3">
              <div className="mt-3">
                <LessonResourceTable lessonId={props.id} refresh={showAddResourceModal} canDownload={props.canDownload} />
              </div>
              <Form.Group className="mb-3" controlId="lessonForm.upload_resource_buttons">
                <Button variant="outline-secondary" size="md" className="mt-4" onClick={()=>setShowAddResourceModal(true)}>{I18n.t('activerecord.attributes.flag.upload_resource')}</Button>
              </Form.Group>
            </Tab>
          ) : ""
        }
      </Tabs>
    </Form>
  );
};